import { awardProduct } from '@/service/luckDraw/index'

export default {
    name: 'win-prize',
    props: {
        show: {
            type: Boolean, // 是否显示
            default: false,
        },
        awardObj: {
            type: Object, // 奖品id
            default: {},
        }
    },
    data(){
        return{
            awardList: [],
            curIndex: -1,
            curRow: {}
        }
    },
    watch: {
        show(val){
            if(val){
                this.curIndex = -1
                this.init()
            }
       } 
    },

    methods:{
        async init(){
            let { awardObj } = this;
            const res = await awardProduct({ awardId: awardObj.id })
            this.awardList = res 
        },
        // 点击选择
        onSelect(row, index){
            this.curRow = row
            this.curIndex = index
        },
        // 关闭回调
        onClose(){
            this.$emit('close')
        },
        // 点击确认
        onConfirm(){
            let { curIndex, curRow, awardObj } = this
            if(curIndex == -1) return
            curRow.level = awardObj.name
            curRow.recordid = awardObj.recordid
            this.$store.commit('luckDraw/setCurPrize', { prize: curRow })

            this.$emit('confirm')
            this.$emit('close')
        }
    }
}