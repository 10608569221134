import { mapState } from 'vuex';
import { getAwardList, getLotteryNumber, luckdraw } from '@/service/luckDraw/index'
import AwardList from './components/awardList/index.vue';
import WinPrize from './components/winPrize/index.vue';
import ReceivePrize from './components/receivePrize/index.vue';

export default {
    name: 'luck-draw-index',
    components: {
        AwardList,
        WinPrize,
        ReceivePrize
    },
    data() {
        return {
            prizes: [], // 奖品列表
            showAwardList: false,
            showWinPrize: false,
            showReceivePrize: false,
            awardObj: {}, //中奖返回的对象
            lotteryNumber: 0, //抽奖次数
        }
    },
    computed:{
        ...mapState({
            userId: state => state.user.user.id
        }),
    },
    // 监听
    watch:{

    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getPrizesList()
            this.getLotterySum()
            this.showReceivePrize = localStorage.getItem('showReceivePrize')? true:false
        },

        // 获取奖品列表
        async getPrizesList(){
            const data = await getAwardList({ activityId: 105 })
            this.prizes = []
            data.forEach((item, index) => {
                this.prizes.push({
                    ...item,
                    title: item.name,
                    background: index % 2 ? '#ff4d4d' : '#fbd5b3',
                    fonts: [{ text: item.name, top: '8%' }],
                    imgs: [{ src: item.picture, width: '30%', top: '25%' }],
                })
            })
        },
        // 获取用户可用抽奖次数
        async getLotterySum(){
            let { userId } = this;
            const data = await getLotteryNumber({ activityId: 105, userId })
            this.lotteryNumber = data
        },
        // 点击抽奖发送请求
        async onLuckdraw(){
            let { userId } = this;
            const res = await luckdraw({ activityId: 105, userId })
            return res
        },
        async startCallBack() {
            let { prizes, userId } = this;

            const data = await this.onLuckdraw()
            this.$refs.LuckyWheel.play()
            this.awardObj = data

            let index = prizes.map(item => item.id).indexOf(data.id)
            this.$refs.LuckyWheel.stop(index)
        },
        endCallBack() {
            this.onClosePrize()
            this.lotteryNumber --
        },

        // 打开关闭奖品弹窗
        onCloseAward(){
            this.showAwardList = !this.showAwardList
        },
        // 打开关闭中奖弹窗
        onClosePrize(){
            this.showWinPrize = !this.showWinPrize
        },
        // 打开关闭领奖弹窗
        onCloseReceive(){
            this.showReceivePrize = !this.showReceivePrize
        },
        // 中奖弹窗 点击 确定
        onConfirmWinPrize(){
            this.onCloseReceive()
        },
        // 点击领取奖品
        onConfirmAward(params){
            this.awardObj = params.awardObj
            this.onClosePrize()
        },
        // 领奖确定
        onConfirmReceivePrize(){

        },
    }
}
