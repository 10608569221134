import { HttpV1 as HTTP } from '@/request'

/**
 * 获取奖品列表
 */
export async function getAwardList (params) {
  return await HTTP.get('/agent/lottery/userAgent/awardList', params)
}

/**
 * 用户可用抽奖次数
 */
export async function getLotteryNumber (params) {
  return await HTTP.get('/agent/lottery/userAgent/lotteryNumber', params)
}

/**
 * 奖品商品信息
 */
export async function awardProduct (params) {
  return await HTTP.get('/agent/lottery/userAgent/awardProduct', params)
}

/**
 * 我的获奖记录
 */
export async function myRecordList (params) {
  return await HTTP.get('/agent/lottery/userAgent/myRecordList', params)
}

/**
 * 更新抽奖记录
 */
export async function updateRecord (params) {
  return await HTTP.post('/agent/lottery/userAgent/updateRecord', params)
}
/**
 * 抽奖操作
 */
export async function luckdraw (params) {
  return await HTTP.get('/management/lottery/luckdraw', params)
}