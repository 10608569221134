import { mapState } from 'vuex'
import { updateRecord } from '@/service/luckDraw/index'

export default {
    name: 'win-prize',
    props: {
        show: {
            type: Boolean, // 是否显示
            default: false,
        }
    },
    data(){
        return{
            
        }
    },
    watch: {
        show(val){
            if(!val){
                localStorage.removeItem('showReceivePrize')
                this.$store.commit('luckDraw/setAddress', { address: {} })
            }
        }
    },
    computed: {
        userinfo() {
          return this.$store.state.user.user;
        },
        ...mapState({
            prize: state => state.luckDraw.curPrize,
            address: state => state.luckDraw.address
        }),
    },
    methods:{
        // 关闭回调
        onClose(){
            this.$emit('close')
        },

        // 选择地址
        onAddress(){
            localStorage.setItem('showReceivePrize', true)
            this.$router.push({
                name: 'address-list',
                query: { from: 'luck-draw-2020-10' }
            })
        },

        // 点击确定
        async onConfirm(){
            let { prize, address } = this;
            if(!address.name) return

            let params = {
                id: prize.recordid,
                awardInfo: [prize.id],
                state: 1,
                address: {
                    name: address.name,
                    phone: address.phone,
                    address: this.$getFullAddress(address.region),
                    fullAddress: address.detail
                }
            }
            const res = await updateRecord(params)
            this.$toast('领取成功')
            this.$emit('close')
        }
    }
}